import PropTypes from "prop-types";
import { createContext, useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
// hooks
import useResponsive from "../hooks/useResponsive";
//reducers
import {
  getIsLoggedIn,
  getIsLoadingVerifyEmailOtp,
  getError,
  getInstitutionId,
  getAllUserInstitutionIds,
  getJwt,
} from "../redux/slices/account";
import { getIsLoadingInstitution } from "../redux/slices/institution";
//actions
import { getInstitutionByDomainName } from "../redux/actions/institution";
import { verifyEmailOtp } from "../redux/actions/account";
//helpers
import { usePrevious } from "../helpers/utils";
//path
import { PATH_DASHBOARD } from "../routes/paths";
import { getUrlOnLogin, storeUrlOnLogin } from "src/redux/slices/navigation";
import { getUserNavigation } from "src/redux/actions/navigation";

// ----------------------------------------------------------------------

const initialState = {
  collapseClick: false,
  collapseHover: false,
  onToggleCollapse: () => { },
  onHoverEnter: () => { },
  onHoverLeave: () => { },
};

const CollapseDrawerContext = createContext(initialState);

// ----------------------------------------------------------------------

CollapseDrawerProvider.propTypes = {
  children: PropTypes.node,
};

function CollapseDrawerProvider({ children }) {
  const search = useLocation().search;
  const navigate = useNavigate();
  const otp = new URLSearchParams(search).get("otp");
  let host = window.location.host;
  let parts = host.split(".");
  // let subdomain = "";
  let subdomain = "related";
  if (parts.length >= 3) {
    subdomain = parts[0];
  }
  const location = useLocation();

  const { pathname } = location
  const isDesktop = useResponsive("up", "lg");
  const dispatch = useDispatch();
  const urlOnLogin = useSelector(getUrlOnLogin);
  const isLoggedIn = useSelector(getIsLoggedIn);
  const isLoadingInstitution = useSelector(getIsLoadingInstitution);
  const error = useSelector(getError);
  const isLoadingVerifyEmailOtp = useSelector(getIsLoadingVerifyEmailOtp);
  const isLoadingVerifyEmailOtpPrev = usePrevious(isLoadingVerifyEmailOtp);

  const jwt = useSelector(getJwt);

  const institutionId = useSelector(getInstitutionId);
  const allUserInstitutionIds = useSelector(getAllUserInstitutionIds);

  const [collapse, setCollapse] = useState({
    click: false,
    hover: false,
  });

  useEffect(() => {
    if (!isLoggedIn) {
      dispatch(
        getInstitutionByDomainName({
          domainName: subdomain ? subdomain : "",
        })
      );
    }
  }, []);

  useEffect(() => {
    if (
      !isLoadingVerifyEmailOtp &&
      isLoadingVerifyEmailOtp !== isLoadingVerifyEmailOtpPrev &&
      isLoadingVerifyEmailOtpPrev !== undefined &&
      !error
    ) {
      if (isLoggedIn) {
        if (!institutionId && allUserInstitutionIds && allUserInstitutionIds?.length > 1) {
          navigate("/institutions/all");
        }
        else {
          dispatch(getUserNavigation({ jwt, institutionId: institutionId }))
          if (urlOnLogin) {
            navigate(urlOnLogin);
          }
          else{
            navigate(PATH_DASHBOARD.account.changePassword);
          }
        }
      }
    }
    else if
      (!isLoadingVerifyEmailOtp &&
      isLoadingVerifyEmailOtp !== isLoadingVerifyEmailOtpPrev &&
      isLoadingVerifyEmailOtpPrev !== undefined &&
      error) {
      if (!isLoggedIn) {
        navigate("/auth/resend-link");
      }
    }
  }, [isLoadingVerifyEmailOtp, isLoadingVerifyEmailOtpPrev]);

  useEffect(() => {
    if (otp) {
      dispatch(verifyEmailOtp(otp));
    }
    if (pathname !== "/auth" && pathname !== "/") {
      dispatch(storeUrlOnLogin(pathname))
    }
  }, [otp]);

  useEffect(() => {
    if (!isDesktop) {
      setCollapse({
        click: false,
        hover: false,
      });
    }
  }, [isDesktop]);

  const handleToggleCollapse = () => {
    setCollapse({ ...collapse, click: !collapse.click });
  };

  const handleHoverEnter = () => {
    if (collapse.click) {
      setCollapse({ ...collapse, hover: true });
    }
  };

  const handleHoverLeave = () => {
    setCollapse({ ...collapse, hover: false });
  };

  return (
    <CollapseDrawerContext.Provider
      value={{
        isCollapse: collapse.click && !collapse.hover,
        collapseClick: collapse.click,
        collapseHover: collapse.hover,
        onToggleCollapse: handleToggleCollapse,
        onHoverEnter: handleHoverEnter,
        onHoverLeave: handleHoverLeave,
      }}
    >
      {isLoadingInstitution ? null : children}
    </CollapseDrawerContext.Provider>
  );
}

export { CollapseDrawerProvider, CollapseDrawerContext };

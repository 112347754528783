import { createSlice } from "@reduxjs/toolkit";
import { logoutUser } from "../actions/account";
import { deleteFilterById, getSavedFilters, postSaveFilter,editSavedFilter } from "../actions/filters";

const initialState = {
    error:false,
    isLoadingGetFilters: false,
    savedFilters: [],
    isLoadingSaveFilters:false,
    isLoadingDeleteFilterById:false,
    isLoadingEditSavedFilters:false
};

const filterSlice =createSlice({
    name:"filters",
    initialState,
    reducers:{
        "account/logoutUser": (state) => initialState
    },
    extraReducers:{
        [postSaveFilter.pending]: (state, action) => {
            state.isLoadingSaveFilters = true;
            state.error = false;
        },
        [postSaveFilter.fulfilled]: (state, action) => {
            state.isLoadingSaveFilters = false;

            state.error = false;
        },
        [postSaveFilter.rejected]: (state, action) => {
            state.isLoadingSaveFilters = false;
            state.error = true;
        },
        [getSavedFilters.pending]: (state, action) => {
            state.isLoadingGetFilters = true;
            state.error = false;
        },
        [getSavedFilters.fulfilled]: (state, action) => {
            state.isLoadingGetFilters = false;
            state.savedFilters = action?.payload?.data;
            state.error = false;
        },        
        [getSavedFilters.rejected]: (state, action) => {
            state.isLoadingGetFilters = false;
            state.error = true;
        },
        [editSavedFilter.pending]: (state, action) => {
            state.isLoadingEditSavedFilters = true;
            state.error = false;
        },
        [editSavedFilter.fulfilled]: (state, action) => {
            state.isLoadingEditSavedFilters = false;
            state.error = false;
        },        
        [editSavedFilter.rejected]: (state, action) => {
            state.isLoadingEditSavedFilters = false;
            state.error = true;
        },
        [deleteFilterById.pending]: (state, action) => {
            state.isLoadingDeleteFilterById = true;
            state.error = false;
        },
        [deleteFilterById.fulfilled]: (state, action) => {
            state.isLoadingDeleteFilterById = false;
            state.error = false;
        },        
        [deleteFilterById.rejected]: (state, action) => {
            state.isLoadingDeleteFilterById = false;
            state.error = true;
        },
        [logoutUser.fulfilled]: (state) => {
            return initialState;
        },        
        [logoutUser.rejected]: (state) => {
            return initialState;
        }

    }
});

export const getError=(state)=>state.filters.error;
export const getIsLoadingGetFilters=(state)=>state.filters.isLoadingGetFilters;
export const getSavedFilteredData=(state)=>state.filters.savedFilters;
export const getIsLoadingSaveFilters=(state)=>state.filters.isLoadingSaveFilters;
export const getIsLoadingDeleteFilterById=(state)=>state.filters.isLoadingDeleteFilterById;
export const getIsLoadingEditSavedFilters=(state)=>state.filters.isLoadingEditSavedFilters;
export default filterSlice;


import { createSlice } from "@reduxjs/toolkit";
import { getHouseAllocation, getAllSimulations, getSimulationById,assignTransfers, unAssignTransfers } from "../actions/nycha";
import { logoutUser } from "../actions/account";

const initialState = {
    error: false,
    selectedClientAndLocationResources: {
        "client": [],
        "location": [],
        "goal": []
    },
    isLoadingHouseAllocation: false,
    allocatedData: [],
    isLoadingAllSimulations: false,
    allSimulations: {},
    isLoadingGetSimulationById: false,
    isLoadingAssignTransfers:false,
    isLoadingUnAssingTransfers:false
};

const nychaSlice = createSlice({
    name: "nycha",
    initialState,
    reducers: {
        "account/logoutUser": (state) => initialState,
        storeNychaResources(state, action) {
            if (action?.payload?.client) {
                state.selectedClientAndLocationResources = Object.assign({}, state.selectedClientAndLocationResources, {
                    "client": action.payload?.client
                });
            } else if (action?.payload?.location) {
                state.selectedClientAndLocationResources = Object.assign({}, state.selectedClientAndLocationResources, {
                    "location": action.payload?.location
                });
            } else if (action?.payload?.goal) {
                state.selectedClientAndLocationResources = Object.assign({}, state.selectedClientAndLocationResources, {
                    "goal": action.payload?.goal
                });
            }
        },
        storeNychaResourcesFromSimulation(state, action) {
            state.selectedClientAndLocationResources = action?.payload?.data
        },
        clearNychaResources(state, action) {
            state.selectedClientAndLocationResources = {
                "client": [],
                "location": [],
                "goal": []
            }
        }

    },
    extraReducers: {
        [getHouseAllocation.pending]: (state, action) => {
            state.isLoadingHouseAllocation = true;
            state.error = false;
        },
        [getHouseAllocation.fulfilled]: (state, action) => {
            state.isLoadingHouseAllocation = false;
            state.allocatedData = action.payload.data;
            state.error = false;
        },
        [getHouseAllocation.rejected]: (state, action) => {
            state.isLoadingHouseAllocation = false;
            state.error = true;
        },//getAllSimulations
        [getAllSimulations.pending]: (state, action) => {
            state.isLoadingAllSimulations = true;
            state.error = false;
        },
        [getAllSimulations.fulfilled]: (state, action) => {
            state.isLoadingAllSimulations = false;
            state.allSimulations = action?.payload?.data;
            state.error = false;
        },
        [getAllSimulations.rejected]: (state, action) => {
            state.isLoadingAllSimulations = false;
            state.error = true;
        },//getSimulationById
        [getSimulationById.pending]: (state, action) => {
            state.isLoadingGetSimulationById = true;
            state.error = false;
        },
        [getSimulationById.fulfilled]: (state, action) => {
            state.isLoadingGetSimulationById = false;
            state.allocatedData = action?.payload?.data;
            state.error = false;
        },
        [getSimulationById.rejected]: (state, action) => {
            state.isLoadingGetSimulationById = false;
            state.error = true;
        },
        [assignTransfers.pending]: (state, action) => {
            state.isLoadingAssignTransfers = true;
            state.error = false;
        },
        [assignTransfers.fulfilled]: (state, action) => {
            state.isLoadingAssignTransfers = false;
            state.error = false;
        },
        [assignTransfers.rejected]: (state, action) => {
            state.isLoadingAssignTransfers = false;
            state.error = true;
        },
        [unAssignTransfers.pending]: (state, action) => {
            state.isLoadingUnAssingTransfers = true;
            state.error = false;
        },
        [unAssignTransfers.fulfilled]: (state, action) => {
            state.isLoadingUnAssingTransfers = false;
            state.error = false;
        },
        [unAssignTransfers.rejected]: (state, action) => {
            state.isLoadingUnAssingTransfers = false;
            state.error = true;
        },
        [logoutUser.fulfilled]: (state) => {
            return initialState;
        },
        [logoutUser.rejected]: (state) => {
            return initialState;
        },
    }
});

export const getError = (state) => state.nycha.error;
export const getSelectedClientAndLocationResources = (state) => state.nycha.selectedClientAndLocationResources;
export const getIsLoadingHouseAllocation = (state) => state.nycha.isLoadingHouseAllocation;
export const getAllocatedData = (state) => state.nycha.allocatedData;
export const getIsLoadingAllSimulations = (state) => state.nycha.isLoadingAllSimulations;
export const getAllSimulationsData = (state) => state.nycha.allSimulations;
export const getIsLoadingSimulationById = (state) => state.nycha.isLoadingGetSimulationById;
export const getIsLoadingAssignTransfers = (state) =>{ 
    return state.nycha.isLoadingAssignTransfers}
export const getIsLoadingUnAssignTransfers = (state)=>state.nycha.isLoadingUnAssingTransfers

export const { storeNychaResources, clearNychaResources, storeNychaResourcesFromSimulation } = nychaSlice.actions;

export default nychaSlice;


import { createSlice } from "@reduxjs/toolkit";
import {
  getGoalSchemaById,
  addGoals,
  getGoalsByTagId,
  getGoalsByMultipleTagIds,
  getGoalsByProductId,
  getGoalById,
  editGoalById,
  editChildGoalById,
  getGoalDetailsForSubSectionEditById,
  getGoalByParentGoalId,
  delGoalById,
  getGoalsForCalender,
  editMultipleGoals,
  getPublicGoalSchema,
  addPublicGoals,
  editBulkGoals,
  getClientLocationProfileForGoal,
  checkGoalConflicts,
  markGoalAsFavorite,
  getGoalByIdLazy,
  checkRecentlyUpdatedGoal,
  createDuplicateGoals,
  getGoalsByModuleId,
  getGoalsByMultipleTagIdsWithPagination,
  getGoalByParentGoalIdWithPagination,
  downloadGoalsCSV,
  filteredParentGoals,
  revertGoals,
  revertGoalstById,
  editBulkGoalsCsv,
  parentGoalsByChildId,
  childDocsbyParentId

} from "../actions/goals";
import { addEmail } from "../actions/email";
import { editSalesOrderById, deleteSalesOrderById, addSalesOrderItem } from "../actions/salesOrder";
import { unLinkGoogleEvent, addCalendarEvent } from "../actions/calendarEvents";
import { logoutUser } from "../actions/account";
import { bulkAssignClient } from "../actions/clients";

const initialState = {
  error: false,
  isLoadingGetGoalSchema: false,
  goalSchema: [],
  otherGoalSchema: [],
  isLoadingAddGoals: false,
  isLoadingGetGoalsByTagId: false,
  isLoadingGetGoalsByMultipleTagId: false,
  isLoadingGetGoalsByProductId: false,
  isLoadingEditGoals: false,
  isLoadingDelGoals: false,
  isLoadingGoalsforCalender: false,
  goalProducts: [],
  goalProductsSchema: [],
  parentGoals: [],
  parentGoalsSchema: [],
  childGoalsSchema: [],
  goalsList: [],
  childGoalsList: [],
  goalsforCalender: [],
  productSpecificGoals: [],
  productSpecificGoalsSchema: [],
  isLoadingGoalById: false,
  isLoadingGoalDetailsForSubSectionEditById: false,
  goalDetails: {},
  goalAllDetails: {},
  otherGoalDetails: {},
  goalProductTag: [],
  goalClientsSchema: [],
  goalClients: [],
  goalVendorsSchema: [],
  goalVendors: [],
  goalLogs: [],
  goalContracts: [],
  goalReports: [],
  goalEmails: [],
  isLoadingEditMultipleGoals: false,
  isLoadingPublicGoalSchema: false,
  publicGoalSchema: [],
  isLoadingAddPublicGoals: false,
  isLoadingEditBulkGoals: false,
  tagItems: [],
  errorMsg: "",
  isLoadingGetGoalLocationClientProfile: false,
  clientLocationProfilesForGoal: [],
  isLoadingCheckGoalConflicts: false,
  goalConflictStatus: "",
  goalsByTagIdsPromise: null,
  isLoadingMarkFavorite: false,
  goalMarkedAsFav: {},
  goalTags: {},
  goalReportsExist: false,
  goalEmailsExist: false,
  goalContractTemplates: [],
  goalEmailTemplates: [],
  goalReportTemplates: [],
  isLoadingCheckRecentlyUpdatedGoal: false,
  wasGoalRecentlyUpdated: false,
  isLoadingCreateDuplicateGoals: false,
  goalsTaglevelSlotBookings: [],
  isLoadingGetGoalsByModuleId: false,
  moduleSpecificGoals: [],
  moduleSpecificGoalsSchema: [],
  totalCount:null,
  totalCountAll:null,
  statusCount:{},
  headerFields:[],
  clientList:{},
  listOfAllGoals:[],
  listOfAllSchema:[],
  isLoadingDownloadGoalsCSV:false,
  clientTag:{},
  isLoadingRevertGoals: false,
  isLoadingRevertGoalsById: false,
  revertGoalsData: [],
  isLoadingEditBulkGoals:false,
  isLoadingEditBulkGoalsCSV:false,
  isLoadingParentGoalsByChildId:false,
  parentGoalsByChildIdData:[],
  parentGoalsSchemaByChildId:[],
  childDocsbyParentIdData:[],
  isLoadingGetChildDocsByParentId:false
};

const goalSlice = createSlice({
  name: "goals",
  initialState,
  reducers: {
    "account/logoutUser": (state) => initialState,

    clearGoalSchema(state) {
      state.goalSchema = [];
    },
    storeGoalsByTagIdsPromise(state, action) {
      state.goalsByTagIdsPromise = action.payload
    },
    markGoalFav(state, action) {
      const tempGoalList = state.goalsList?.map(x => {
        if (x?._id === action?.payload?.id) {
          return Object.assign({}, x, {
            isFavorite: action?.payload?.value
          })
        }
        return x
      });
      state.goalsList = tempGoalList
    }
  },
  extraReducers: {
    [getGoalSchemaById.pending]: (state, action) => {
      state.isLoadingGetGoalSchema = true;
      state.error = false;
    },
    [getGoalSchemaById.fulfilled]: (state, action) => {
      state.isLoadingGetGoalSchema = false;
      state.goalSchema = action?.payload?.data;
      state.error = false;
    },
    [getGoalSchemaById.rejected]: (state, action) => {
      state.isLoadingGetGoalSchema = false;
      state.error = true;
    },//addEmail
    [addEmail.fulfilled]: (state, action) => {
      if (action?.payload?.module === "goal") {
        state.goalEmails = [...(state?.goalEmails?.length ? state?.goalEmails : []), ...(action?.payload?.returnedData?.data?.length ? action?.payload?.returnedData?.data : [])];
      }
      state.error = false;
    },
    [getGoalsForCalender.pending]: (state, action) => {
      state.isLoadingGoalsforCalender = true;
      state.error = false;
    },
    [getGoalsForCalender.fulfilled]: (state, action) => {
      state.isLoadingGoalsforCalender = false;
      state.goalsforCalender = action?.payload?.data?.goals;
      state.goalsforCalenderSchema = action?.payload?.data?.schema;
      state.error = false;
    },
    [getGoalsForCalender.rejected]: (state, action) => {
      state.isLoadingGoalsforCalender = false;
      state.error = true;
    },//getClientLocationProfileForGoal
    [getClientLocationProfileForGoal.pending]: (state, action) => {
      state.isLoadingGetGoalLocationClientProfile = true;
      state.error = false;
    },
    [getClientLocationProfileForGoal.fulfilled]: (state, action) => {
      state.isLoadingGetGoalLocationClientProfile = false;
      state.clientLocationProfilesForGoal = action?.payload?.data;
      state.error = false;
    },
    [getClientLocationProfileForGoal.rejected]: (state, action) => {
      state.isLoadingGetGoalLocationClientProfile = false;
      state.error = true;
    },
    [addGoals.pending]: (state, action) => {
      state.isLoadingAddGoals = true;
      state.error = false;
    },
    [addGoals.fulfilled]: (state, action) => {
      state.isLoadingAddGoals = false;
      state.goalDetails = action?.payload?.data?.goal
      state.goalAllDetails = action?.payload?.data?.goal
        ? action?.payload?.data?.goal
        : {};
      state.error = false;
    },
    [addGoals.rejected]: (state, action) => {
      state.isLoadingAddGoals = false;
      state.error = true;
    },//createDuplicateGoals
    [createDuplicateGoals.pending]: (state, action) => {
      state.isLoadingCreateDuplicateGoals = true;
      state.error = false;
    },
    [createDuplicateGoals.fulfilled]: (state, action) => {
      state.isLoadingCreateDuplicateGoals = false;
      state.error = false;
    },
    [createDuplicateGoals.rejected]: (state, action) => {
      state.isLoadingCreateDuplicateGoals = false;
      state.error = true;
    },
    [getGoalsByTagId.pending]: (state, action) => {
      state.isLoadingGetGoalsByTagId = true;
      state.error = false;
    },
    [getGoalsByTagId.fulfilled]: (state, action) => {
      state.isLoadingGetGoalsByTagId = false;
      state.goalsList = action?.payload?.data?.goals;
      state.goalSchema = action?.payload?.data?.schema;
      state.error = false;
    },
    [getGoalsByTagId.rejected]: (state, action) => {
      state.isLoadingGetGoalsByTagId = false;
      state.error = true;
    },
    [getGoalByParentGoalId.pending]: (state, action) => {
      state.isLoadingGoalByParentGoalId = true;
      state.error = false;
    },
    [getGoalByParentGoalId.fulfilled]: (state, action) => {
      state.isLoadingGoalByParentGoalId = false;
      state.childGoalsList = action?.payload?.data?.goal;
      state.childGoalsSchema = action?.payload?.data?.goals_schema;
      state.error = false;
    },
    [getGoalByParentGoalId.rejected]: (state, action) => {
      state.isLoadingGoalByParentGoalId = false;
      state.error = true;
    },
    [getGoalByParentGoalIdWithPagination.pending]: (state, action) => {
      state.isLoadingGoalByParentGoalId = true;
      state.error = false;
    },
    [getGoalByParentGoalIdWithPagination.fulfilled]: (state, action) => {
      state.isLoadingGoalByParentGoalId = false;
      state.childGoalsList = action?.payload?.data?.goal;
      state.childGoalsSchema = action?.payload?.data?.goals_schema;
      state.totalCount=action?.payload?.data?.allGoalCount
      state.statusCount=action?.payload?.data?.goal_status_count
      state.totalCountAll =action?.payload?.data?.countAfterFilter
      state.error = false;
    },
    [getGoalByParentGoalIdWithPagination.rejected]: (state, action) => {
      state.isLoadingGoalByParentGoalId = false;
      state.error = true;
    },
    [getGoalsByMultipleTagIds.pending]: (state, action) => {
      state.isLoadingGetGoalsByMultipleTagId = true;
      state.error = false;
    },
    [getGoalsByMultipleTagIds.fulfilled]: (state, action) => {
      state.isLoadingGetGoalsByMultipleTagId = false;
      state.goalsList = action?.payload?.goals;
      state.tagItems = action?.payload?.items;
      state.goalSchema = action?.payload?.schema;
      state.goalReportTemplates = action?.payload?.report_template;
      state.goalsByTagIdsPromise = null;
      state.totalCount=action?.payload?.count
      state.statusCount=action?.payload?.goal_status_count
      state.error = false;
    },
    
    [getGoalsByMultipleTagIdsWithPagination.fulfilled]: (state, action) => {
      state.isLoadingGetGoalsByMultipleTagId = false;
      state.headerFields=action?.payload?.headerFieldResult
      state.goalsList = action?.payload?.goals;
      state.goalSchema = action?.payload?.schema;
      state.tagItems = action?.payload?.items;
      state.goalReportTemplates = action?.payload?.report_template;
      state.totalCount=action?.payload?.allGoalCount
      state.totalCountAll =action?.payload?.countAfterFilter
      state.statusCount=action?.payload?.goal_status_count
      state.goalsByTagIdsPromise = null;
      state.error = false;
    },
    [getGoalsByMultipleTagIdsWithPagination.rejected]: (state, action) => {
      state.isLoadingGetGoalsByMultipleTagId = false;
      state.error = true;
    },
    [getGoalsByMultipleTagIdsWithPagination.pending]: (state, action) => {
      state.isLoadingGetGoalsByMultipleTagId = true;
      state.error = false;
    },
    [getGoalsByMultipleTagIds.rejected]: (state, action) => {
      state.isLoadingGetGoalsByMultipleTagId = false;
      state.goalsList = [];
      state.goalSchema = [];
      state.error = true;
    },
    [getGoalsByProductId.pending]: (state, action) => {
      state.isLoadingGetGoalsByProductId = true;
      state.error = false;
    },
    [getGoalsByProductId.fulfilled]: (state, action) => {
      state.isLoadingGetGoalsByProductId = false;
      state.productSpecificGoals = action?.payload?.data?.goals;
      state.productSpecificGoalsSchema = action?.payload?.data?.schema;
      state.error = false;
    },
    [getGoalsByProductId.rejected]: (state, action) => {
      state.isLoadingGetGoalsByProductId = false;
      state.error = true;
    },
    [getGoalsByModuleId.pending]: (state, action) => {
      state.isLoadingGetGoalsByModuleId = true;
      state.error = false;
    },
    [getGoalsByModuleId.fulfilled]: (state, action) => {
      state.isLoadingGetGoalsByModuleId = false;
      state.moduleSpecificGoals = action?.payload?.data?.goals;
      state.moduleSpecificGoalsSchema = action?.payload?.data?.schema;
      state.totalCount=action?.payload?.data?.allGoalCount
      state.statusCount=action?.payload?.data?.goal_status_count
      state.totalCountAll =action?.payload?.data?.countAfterFilter
      state.error = false;
    },
    [getGoalsByModuleId.rejected]: (state, action) => {
      state.isLoadingGetGoalsByModuleId = false;
      state.error = true;
    },
    [getGoalByIdLazy.pending]: (state, action) => {
      state.isLoadingGoalById = true;
      state.error = false;
    },
    [getGoalByIdLazy.fulfilled]: (state, action) => {
      state.isLoadingGoalById = false;
      state.goalDetails = action?.payload?.data?.goal;
      state.goalSchema = action?.payload?.data?.goals_schema;
      state.goalTags = action?.payload?.data?.goal_tag;
      state.goalReportsExist = action?.payload?.data?.report_exists;
      state.goalEmailsExist = action?.payload?.data?.email_exists;
      state.goalContractTemplates = action?.payload?.data?.contract_template;
      state.goalEmailTemplates = action?.payload?.data?.email_template;
      state.goalReportTemplates = action?.payload?.data?.report_template;
      state.goalProductTag = action?.payload?.data?.product_tag;
      state.goalLogs = action?.payload?.data?.logs;
      state.goalContracts = action?.payload?.data?.contracts;
      state.goalsTaglevelSlotBookings = action?.payload?.data?._slots_data_multi;
      state.error = false;
      state.workorderTags = action?.payload?.data?.vendor_work_tag;
      state.clientList=action?.payload?.data?.client_tag
      state.clientTag=action?.payload?.data?.client_tag
    },
    [getGoalByIdLazy.rejected]: (state, action) => {
      state.isLoadingGoalById = false;
      state.error = true;
    },
    [getGoalById.pending]: (state, action) => {
      state.isLoadingGoalById = true;
      state.error = false;
    },
    [getGoalById.fulfilled]: (state, action) => {
      state.isLoadingGoalById = false;
      state.goalAllDetails = action?.payload?.data?.goal;
      state.goalSchema = action?.payload?.data?.goals_schema;
      state.goalProductsSchema = action?.payload?.data?.products_schema;
      state.parentGoals = action?.payload?.data?.parent_goals;
      state.parentGoalsSchema = action?.payload?.data?.parent_goals_schema;
      state.goalProductTag = action?.payload?.data?.products_tags;
      state.goalClientsSchema = action?.payload?.data?.clients_schema;
      state.goalClients = action?.payload?.data?.clients;
      state.goalVendorsSchema = action?.payload?.data?.vendors_schema;
      state.goalVendors = action?.payload?.data?.vendors;
      state.goalLogs = action?.payload?.data?.logs;
      state.goalProducts = action?.payload?.data?.products;
      state.goalContracts = action?.payload?.data?.contracts;
      state.goalReports = action?.payload?.data?.reports;
      state.goalEmails = action?.payload?.data?.emails;
      state.error = false;
      state.workorderTags = action?.payload?.data?.vendor_work_tag;
    },
    [getGoalById.rejected]: (state, action) => {
      state.isLoadingGoalById = false;
      state.error = true;
    },//markGoalAsFavorite
    [markGoalAsFavorite.pending]: (state, action) => {
      state.isLoadingMarkFavorite = true;
      state.error = false;
    },
    [markGoalAsFavorite.fulfilled]: (state, action) => {
      state.isLoadingMarkFavorite = false;
      state.goalMarkedAsFav = action?.payload?.validateData?.data?.goal;
      state.error = false;
    },
    [markGoalAsFavorite.rejected]: (state, action) => {
      state.isLoadingMarkFavorite = false;
      state.goalMarkedAsFav = { _id: action?.payload?.goalId, isFavorite: action?.payload?.isFavorite };
      state.error = true;
    },//checkRecentlyUpdatedGoal
    [checkRecentlyUpdatedGoal.pending]: (state, action) => {
      state.isLoadingCheckRecentlyUpdatedGoal = true;
      state.error = false;
    },
    [checkRecentlyUpdatedGoal.fulfilled]: (state, action) => {
      state.isLoadingCheckRecentlyUpdatedGoal = false;
      state.wasGoalRecentlyUpdated = action?.payload?.data;
      state.error = false;
    },
    [checkRecentlyUpdatedGoal.rejected]: (state, action) => {
      state.isLoadingCheckRecentlyUpdatedGoal = false;
      state.error = true;
    },
    [getGoalDetailsForSubSectionEditById.pending]: (state, action) => {
      state.isLoadingGoalDetailsForSubSectionEditById = true;
      state.error = false;
    },
    [getGoalDetailsForSubSectionEditById.fulfilled]: (state, action) => {
      state.isLoadingGoalDetailsForSubSectionEditById = false;
      if (!action?.payload?.otherGoalFetch) {
        state.goalDetails = action?.payload?.validateData?.data?.goal;
        state.goalSchema = action?.payload?.validateData?.data?.goals_schema;
        state.goalTags = action?.payload?.validateData?.data?.goal_tag;
        state.goalReportsExist = action?.payload?.validateData?.data?.reportsExist;
        state.goalEmailsExist = action?.payload?.validateData?.data?.emailsExist;
        state.goalContractTemplates = action?.payload?.validateData?.data?.contract_template;
        state.goalEmailTemplates = action?.payload?.validateData?.data?.email_template;
        state.goalReportTemplates = action?.payload?.validateData?.data?.report_template;
        state.goalProductTag = action?.payload?.validateData?.data?.product_tag;
        state.goalLogs = action?.payload?.validateData?.data?.logs;
        state.goalContracts = action?.payload?.validateData?.data?.contracts;
        state.error = false;
        state.workorderTags = action?.payload?.validateData?.data?.vendor_work_tag;
      }
      else {
        state.otherGoalDetails = action?.payload?.validateData?.data?.goal;
        state.otherGoalSchema = action?.payload?.validateData?.data?.goals_schema;
      }
      state.error = false;
    },
    [getGoalDetailsForSubSectionEditById.rejected]: (state, action) => {
      state.isLoadingGoalDetailsForSubSectionEditById = false;
      state.error = true;
    },
    [editGoalById.pending]: (state, action) => {
      state.isLoadingEditGoals = true;
      state.error = false;
    },
    [editGoalById.fulfilled]: (state, action) => {
      state.goalDetails = action?.payload?.data?.goal;
      state.goalAllDetails = action?.payload?.data?.goal;
      state.goalSchema = action?.payload?.data?.goals_schema;
      state.goalTags = action?.payload?.data?.goal_tag;
      state.goalReportsExist = action?.payload?.data?.report_exists;
      state.goalEmailsExist = action?.payload?.data?.email_exists;
      state.goalContractTemplates = action?.payload?.data?.contract_template;
      state.goalEmailTemplates = action?.payload?.data?.email_template;
      state.goalReportTemplates = action?.payload?.data?.report_template;
      state.goalProductTag = action?.payload?.data?.product_tag;
      state.goalLogs = action?.payload?.data?.logs;
      state.goalContracts = action?.payload?.data?.contracts;
      state.workorderTags = action?.payload?.data?.vendor_work_tag;
      const temp = action?.payload?.data?.goal;
      state.goalsforCalender = state.goalsforCalender?.map(
        (obj) => [temp]?.find((o) => o?._id === obj?._id) || obj
      );
      state.isLoadingEditGoals = false;
      state.error = false;
    },
    [editGoalById.rejected]: (state, action) => {
      state.isLoadingEditGoals = false;
      state.error = true;
      state.errorMsg = action?.payload?.statusCode && action?.payload?.statusCode === 409 ? "This page was recently edited in another tab. Please reload the page to view the latest data, or press submit again to overwrite with the new data above." : ""
    },
    [editChildGoalById.pending]: (state, action) => {
      state.isLoadingEditChildGoal = true;
      state.error = false;
    },
    [editChildGoalById.fulfilled]: (state, action) => {
      state.isLoadingEditChildGoal = false;
      state.error = false;
    },
    [editChildGoalById.rejected]: (state, action) => {
      state.isLoadingEditChildGoal = false;
      state.error = true;
      state.errorMsg = action?.payload?.statusCode && action?.payload?.statusCode === 409 ? "This page was recently edited in another tab. Please reload the page to view the latest data, or press submit again to overwrite with the new data above." : ""
    },
    [delGoalById.pending]: (state, action) => {
      state.isLoadingDelGoals = true;
      state.error = false;
    },
    [delGoalById.fulfilled]: (state, action) => {
      state.isLoadingDelGoals = false;
      state.error = false;
    },
    [delGoalById.rejected]: (state, action) => {
      state.isLoadingDelGoals = false;
      state.error = true;
    },
    [editMultipleGoals.pending]: (state, action) => {
      state.isLoadingEditMultipleGoals = true;
      state.error = false;
    },
    [editMultipleGoals.fulfilled]: (state, action) => {
      state.isLoadingEditMultipleGoals = false;
      state.error = false;
    },
    [editMultipleGoals.rejected]: (state, action) => {
      state.isLoadingEditMultipleGoals = false;
      state.error = true;
    },
    [getPublicGoalSchema.pending]: (state, action) => {
      state.isLoadingPublicGoalSchema = true;
      state.error = false;
    },
    [getPublicGoalSchema.fulfilled]: (state, action) => {
      state.isLoadingPublicGoalSchema = false;
      state.publicGoalSchema = action?.payload?.data;
      state.error = false;
    },
    [getPublicGoalSchema.rejected]: (state, action) => {
      state.isLoadingPublicGoalSchema = false;
      state.error = true;
    },
    [addPublicGoals.pending]: (state, action) => {
      state.isLoadingAddPublicGoals = true;
      state.error = false;
    },
    [addPublicGoals.fulfilled]: (state, action) => {
      state.isLoadingAddPublicGoals = false;
      state.goalDetails = action?.payload?.data?.goal;
      state.goalSchema = action?.payload?.data?.goalSchema;
      state.error = false;
    },
    [addPublicGoals.rejected]: (state, action) => {
      state.isLoadingAddPublicGoals = false;
      state.error = true;
    },
    [editBulkGoals.pending]: (state, action) => {
      state.isLoadingEditBulkGoals = true;
      state.error = false;
    },
    [editBulkGoals.fulfilled]: (state, action) => {
      state.isLoadingEditBulkGoals = false;
      state.error = false;
    },
    [editBulkGoals.rejected]: (state, action) => {
      state.isLoadingEditBulkGoals = false;
      state.error = true;
    },
    [editSalesOrderById.pending]: (state, action) => {
      state.error = false;
    },
    [editSalesOrderById.fulfilled]: (state, action) => {
      state.goalDetails = action?.payload?.data?.goal;
      state.goalAllDetails = action?.payload?.data?.goal;
      state.error = false;
    },
    [editSalesOrderById.rejected]: (state, action) => {
      state.error = true;
    },
    [deleteSalesOrderById.pending]: (state, action) => {
      state.error = false;
    },
    [deleteSalesOrderById.fulfilled]: (state, action) => {
      state.goalDetails = action?.payload?.data;
      state.goalAllDetails = action?.payload?.data;
      state.error = false;
    },
    [deleteSalesOrderById.rejected]: (state, action) => {
      state.error = true;
    },
    [checkGoalConflicts.pending]: (state, action) => {
      state.isLoadingCheckGoalConflicts = true;
      state.error = false;
    },//checkGoalConflicts
    [checkGoalConflicts.fulfilled]: (state, action) => {
      state.isLoadingCheckGoalConflicts = false;
      state.goalConflictStatus = action?.payload?.data?.conflict;
      state.error = false;
    },
    [checkGoalConflicts.rejected]: (state, action) => {
      state.isLoadingCheckGoalConflicts = false;
      state.error = true;
    },
    [addSalesOrderItem.pending]: (state, action) => {
      state.error = false;
    },
    [addSalesOrderItem.fulfilled]: (state, action) => {
      state.goalDetails = action?.payload?.data?.goal;
      state.goalAllDetails = action?.payload?.data?.goal;
      state.error = false;
    },
    [addSalesOrderItem.rejected]: (state, action) => {
      state.error = true;
    },//unLinkGoogleEvent
    [unLinkGoogleEvent.pending]: (state, action) => {
      state.error = false;
    },
    [unLinkGoogleEvent.fulfilled]: (state, action) => {
      state.goalDetails = action?.payload?.data
      state.goalAllDetails = action?.payload?.data
      state.error = false;
    },
    [unLinkGoogleEvent.rejected]: (state, action) => {
      state.error = true;
    },//addCalendarEvent
    [addCalendarEvent.pending]: (state, action) => {
      state.error = false;
    },
    [addCalendarEvent.fulfilled]: (state, action) => {
      state.goalDetails = action?.payload?.data
      state.goalAllDetails = action?.payload?.data
      state.error = false;
    },
    [addCalendarEvent.rejected]: (state, action) => {
      state.error = true;
    },
    [downloadGoalsCSV.rejected]:(state,action)=>{
      state.error=true;
      state.isLoadingDownloadGoalsCSV=false
    },
    [downloadGoalsCSV.fulfilled]:(state,action)=>{
      state.error=false;
      state.listOfAllGoals=action?.payload?.goals
      state.listOfAllSchema=action?.payload?.schema
      state.isLoadingDownloadGoalsCSV=false
    },
    [downloadGoalsCSV.pending]:(state,action)=>{
      state.error=false;
      state.isLoadingDownloadGoalsCSV=true
    },
    [filteredParentGoals.rejected]:(state,action)=>{
      state.error=true;
      state.isLoadingDownloadGoalsCSV=false
    },
    [filteredParentGoals.fulfilled]:(state,action)=>{
      state.error=false;
      state.listOfAllGoals=action?.payload?.data?.goal
      state.listOfAllSchema=action?.payload?.data?.schema
      state.isLoadingDownloadGoalsCSV=false
    },
    [filteredParentGoals.pending]:(state,action)=>{
      state.error=false;
      state.isLoadingDownloadGoalsCSV=true
    },
    [revertGoals.pending]: (state, action) => {
      state.isLoadingRevertGoals = true;
      state.error = false
    },
    [revertGoals.fulfilled]: (state, action) => {
      state.isLoadingRevertGoals = false;
      state.error = false;
      state.revertGoalsData = action?.payload?.data;
    },
    [revertGoals.rejected]: (state, action) => {
      state.isLoadingRevertGoals = false;
      state.error = true;
    },
    [revertGoalstById.pending]: (state, action) => {
      state.isLoadingRevertGoalsById = true;
      state.error = false
    },
    [revertGoalstById.fulfilled]: (state, action) => {
      state.isLoadingRevertGoalsById = false;
      state.error = false
    },
    [revertGoalstById.rejected]: (state, action) => {
      state.isLoadingRevertGoalsById = false;
      state.error = true
    },
    [editBulkGoalsCsv.pending]: (state, action) => {
      state.isLoadingEditBulkGoalsCSV = true;
      state.error = false;
    },
    [editBulkGoalsCsv.fulfilled]: (state, action) => {
      state.isLoadingEditBulkGoalsCSV = false;
      state.error = false;
    },
    [editBulkGoalsCsv.rejected]: (state, action) => {
      state.isLoadingEditBulkGoalsCSV = false;
      state.error = true
    },
    [parentGoalsByChildId.pending]:(state,action)=>{
      state.error = false
      state.isLoadingParentGoalsByChildId=true
    },
    [parentGoalsByChildId.fulfilled]:(state,action)=>{
      state.error = false
      state.isLoadingParentGoalsByChildId=false
      state.parentGoalsByChildIdData=action?.payload?.data?.parent_goals
      state.parentGoalsSchemaByChildId=action?.payload?.data?.schema
    },
    [parentGoalsByChildId.rejected]:(state,action)=>{
      state.error = true
      state.isLoadingParentGoalsByChildId=false
    },
    [childDocsbyParentId.pending]:(state,action)=>{
      state.error = false
      state.isLoadingGetChildDocsByParentId=true
    },
    [childDocsbyParentId.fulfilled]:(state,action)=>{
      state.error = false
      state.isLoadingGetChildDocsByParentId=false
      state.childDocsbyParentIdData=action?.payload?.data
 
    },
    [childDocsbyParentId.rejected]:(state,action)=>{
      state.error = true
      state.isLoadingGetChildDocsByParentId=false
    },
    [bulkAssignClient.fulfilled]: (state, action) => {
      state.error = false
      state.goalDetails = action?.payload?.data?.goal;
    },
    [logoutUser.fulfilled]: (state) => {
      return initialState;
    },
    [logoutUser.rejected]: (state) => {
      return initialState;
    },
  },
});
export const getError = (state) => state.goals.error;
export const getIsLoadingGetGoalSchema = (state) =>
  state.goals.isLoadingGetGoalSchema;
export const getIsLoadingAddGoals = (state) => state.goals.isLoadingAddGoals;
export const getIsLoadingGetGoalsByTagId = (state) =>
  state.goals.isLoadingGetGoalsByTagId;
export const getIsLoadingGetGoalsByMultipleTagId = (state) =>
  state.goals.isLoadingGetGoalsByMultipleTagId;
export const getIsLoadingGetGoalsbyProductId = (state) =>
  state.goals.isLoadingGetGoalsByProductId;
export const getIsLoadingEditGoals = (state) => state.goals.isLoadingEditGoals;
export const getIsLoadingDelGoals = (state) => state.goals.isLoadingDelGoals;
export const getGoalSchema = (state) => state.goals.goalSchema;
export const getGoalsList = (state) => state.goals.goalsList;
export const getChildGoalsList = (state) => state.goals.childGoalsList;
export const getProductSpeicificGoals = (state) =>
  state.goals.productSpecificGoals;
export const getProductSpecificGoalsSchema = (state) =>
  state.goals.productSpecificGoalsSchema;
export const getIsLoadingGoalById = (state) => state.goals.isLoadingGoalById;
export const getIsLoadingGoalDetailsForSubSectionEditById = (state) => state.goals.isLoadingGoalDetailsForSubSectionEditById;
export const getGoalDetails = (state) => state.goals.goalDetails;
export const getGoalAllDetails = (state) => state.goals.goalAllDetails;
export const getIsLoadingGoalByParentGoalId = (state) =>
  state.goals.isLoadingGoalByParentGoalId;
export const getGoalProductsSchema = (state) => state.goals.goalProductsSchema;
export const getParentGoalsSchema = (state) => state.goals.parentGoalsSchema;
export const getGoalProducts = (state) => state.goals.goalProducts;
export const getParentGoals = (state) => state.goals.parentGoals;
export const getIsLoadingGoalsForCalender = (state) =>
  state.goals.isLoadingGoalsforCalender;
export const getGoalsListForCalender = (state) => state.goals.goalsforCalender;
export const getGoalsforCalenderSchema = (state) =>
  state.goals.goalsforCalenderSchema;
export const getChildGoalsSchema = (state) => state.goals.childGoalsSchema;
export const getGoalProductTag = (state) => state.goals.goalProductTag;
export const getGoalClientsSchema = (state) => state.goals.goalClientsSchema;
export const getGoalClients = (state) => state.goals.goalClients;
export const getGoalVendorsSchema = (state) => state.goals.goalVendorsSchema;
export const getGoalVendors = (state) => state.goals.goalVendors;
export const getGoalLogs = (state) => state.goals.goalLogs;
export const getGoalContracts = (state) => state.goals.goalContracts;
export const getGoalReports = (state) => state.goals.goalReports;
export const getGoalEmails = (state) => state.goals.goalEmails;
export const getIsLoadingEditMultipleGoals = (state) =>
  state.goals.isLoadingEditMultipleGoals;
export const getIsLoadingPublicGoalSchema = (state) =>
  state.goals.isLoadingPublicGoalSchema;
export const getPublicGoalSchemaList = (state) => state.goals.publicGoalSchema;
export const getIsLoadingAddPublicGoals = (state) =>
  state.goals.isLoadingAddPublicGoals;
export const getIsLoadingEditBulkGoals = (state) => state.goals.isLoadingEditBulkGoals;
export const getIsLoadingGetGoalLocationClientProfile = (state) => state.goals.isLoadingGetGoalLocationClientProfile;
export const getClientLocationProfilesForGoal = (state) => state.goals.clientLocationProfilesForGoal;
export const getIsLoadingCheckGoalConflicts = (state) => state.goals.isLoadingCheckGoalConflicts;
export const getTagItems = (state) => state.goals.tagItems;
export const getErrorMsg = (state) => state.goals.errorMsg;
export const getGoalConflictStatus = (state) => state.goals.goalConflictStatus;
export const getgoalsByTagIdsPromise = (state) => state.goals.goalsByTagIdsPromise;
export const getWorkorderTags = (state) => state.goals.workorderTags;
export const getIsLoadingMarkFavorite = (state) => state.goals.isLoadingMarkFavorite;
export const getGoalMarkedAsFav = (state) => state.goals.goalMarkedAsFav;
export const getOtherGoalDetails = (state) => state.goals.otherGoalDetails;
export const getOtherGoalSchema = (state) => state.goals.otherGoalSchema;
export const getAllGoalTags = (state) => state.goals.goalTags;
export const getGoalReportsExist = (state) => state.goals.goalReportsExist;
export const getGoalEmailsExist = (state) => state.goals.goalEmailsExist;
export const getGoalContractTemplates = (state) => state.goals.goalContractTemplates;
export const getGoalEmailTemplates = (state) => state.goals.goalEmailTemplates;
export const getGoalReportTemplates = (state) => state.goals.goalReportTemplates;
export const getIsLoadingCheckRecentlyUpdatedGoal = (state) => state.goals.isLoadingCheckRecentlyUpdatedGoal;
export const getWasGoalRecentlyUpdated = (state) => state.goals.wasGoalRecentlyUpdated;
export const getIsLoadingCreateDuplicateGoals = (state) => state.goals.isLoadingCreateDuplicateGoals;
export const getIsLoadingEditChildGoal = (state) => state.goals.isLoadingEditChildGoal;
export const getGoalsTaglevelSlotBookings = (state) => state.goals.goalsTaglevelSlotBookings;
export const getIsLoadingGetGoalsByModuleId = (state) => state.goals.isLoadingGetGoalsByModuleId;
export const getModuleSpecificGoals = (state) => state.goals.moduleSpecificGoals;
export const getModuleSpecificGoalsSchema = (state) => state.goals.moduleSpecificGoalsSchema;
export const getTotalCount = (state) =>state.goals.totalCount
export const getTotalCountAll = (state) =>state.goals.totalCountAll
export const getStatusCount = (state) =>state.goals.statusCount
export const getHeaderFields = (state) =>state.goals.headerFields
export const getClientListByGoal =  (state) =>state.goals.clientList
export const getListOfAllGoalsFromCSV = (state) =>state.goals.listOfAllGoals
export const getListOfAllSchemaFromCSV = (state) =>state.goals.listOfAllSchema
export const getIsLoadingDownloadGoalsCSV = (state) =>state.goals.isLoadingDownloadGoalsCSV
export const getClientTags = (state) =>state.goals.clientTag
export const getIsLoadingRevertGoals = (state) =>state.goals.isLoadingRevertGoals
export const getIsLoadingRevertGoalsBy=(state)=>state.goals.isLoadingRevertGoalsById
export const getRevertGoalsData=(state)=>state.goals.revertGoalsData
export const getIsLoadingEditBulkGoalsCSV=(state)=>state.goals.isLoadingEditBulkGoalsCSV
export const getParentGoalsByChildId =(state)=>state.goals.parentGoalsByChildIdData
export const getParentGoalsSchemaByChildId=(state)=>state.goals.parentGoalsSchemaByChildId
export const getLoadingGetChildDocsByParentId=(state)=>state.goals.isLoadingGetChildDocsByParentId
export const getChildDocsbyParentIdData=(state)=>state.goals.childDocsbyParentIdData
export const {
  clearGoalSchema,
  storeGoalsByTagIdsPromise,
  markGoalFav
} = goalSlice.actions;

export default goalSlice;

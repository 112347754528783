import { createAsyncThunk } from "@reduxjs/toolkit";
import { checkStatus, composeAuth, AuthError } from "../../helpers/utils";
import { logoutUser } from "./account";

export const getTaskByInstitutionId = createAsyncThunk(
    "tasks/getTaskByInstitutionId",
    async (payload, thunkApi) => {
      try {
        const url = process.env.REACT_APP_SERVER + `/api/task/institution/${payload.institutionId}/get-task?offset=${payload?.offset || 0}&limit=${payload?.limit || 20}`;
  
        const Authorization = composeAuth(payload?.jwt);
  
        const res = await fetch(url, {
          method: "PATCH",
          headers: {
            "Content-type": "application/json",
            Authorization,
          },
          body: JSON.stringify(payload?.requestBody),
        });
  
        const response = await res.json();
  
        const validateData = checkStatus(response);
        return validateData;
      } catch (err) {
        if (err instanceof AuthError) {
          thunkApi.dispatch(logoutUser());
        }
        return thunkApi.rejectWithValue(err);
      }
    }
  );

  export const getTaskByModuleId = createAsyncThunk(
    "tasks/getTaskByModuleId",
    async (payload, thunkApi) => {
      const url=payload?.isUser?process.env.REACT_APP_SERVER + `/api/task/institution/${payload.institutionId}/module/${payload.moduleId}/getTaskByModuleId/me?offset=${payload?.offset || 0}&limit=${payload?.limit || 20}`:
      process.env.REACT_APP_SERVER + `/api/task/institution/${payload.institutionId}/module/${payload.moduleId}/getTaskByModuleId?offset=${payload?.offset || 0}&limit=${payload?.limit || 20}`;
      try {
  
        const Authorization = composeAuth(payload?.jwt);
  
        const res = await fetch(url, {
          method: "PATCH",
          headers: {
            "Content-type": "application/json",
            Authorization,
          },
          body: JSON.stringify(payload?.requestBody),
        });
  
        const response = await res.json();
  
        const validateData = checkStatus(response);
        return validateData;
      } catch (err) {
        if (err instanceof AuthError) {
          thunkApi.dispatch(logoutUser());
        }
        return thunkApi.rejectWithValue(err);
      }
    }
  );
  export const getTaskByProfileId = createAsyncThunk(
    "tasks/getTaskByModuleId",
    async (payload, thunkApi) => {
      try {
        const url = process.env.REACT_APP_SERVER + `/api/task/profile/${payload?.profileId}/getProfileTask?offset=${payload?.offset || 0}&limit=${payload?.limit || 20}`;
  
        const Authorization = composeAuth(payload?.jwt);
  
        const res = await fetch(url, {
          method: "PATCH",
          headers: {
            "Content-type": "application/json",
            Authorization,
          },
          body: JSON.stringify(payload?.requestBody),
        });
  
        const response = await res.json();
  
        const validateData = checkStatus(response);
        return validateData;
      } catch (err) {
        if (err instanceof AuthError) {
          thunkApi.dispatch(logoutUser());
        }
        return thunkApi.rejectWithValue(err);
      }
    }
  );


  
  export const getTaskByProfile = createAsyncThunk(
    "tasks/getTaskByProfile",
    async (payload, thunkApi) => {
      try {
        const url = process.env.REACT_APP_SERVER + `/api/task/profile/me?offset=${payload?.offset || 0}&limit=${payload?.limit || 20}`;
  
        const Authorization = composeAuth(payload?.jwt);
  
        const res = await fetch(url, {
          method: "PATCH",
          headers: {
            "Content-type": "application/json",
            Authorization,
          },
          body: JSON.stringify(payload?.requestBody),
        });
  
        const response = await res.json();
  
        const validateData = checkStatus(response);
        return validateData;
      } catch (err) {
        if (err instanceof AuthError) {
          thunkApi.dispatch(logoutUser());
        }
        return thunkApi.rejectWithValue(err);
      }
    }
  );
  export const getSubtaskByParentTaskId = createAsyncThunk(
    "tasks/getSubtaskByParenTaskId",
    async (payload, thunkApi) => {
      try {
        const url = process.env.REACT_APP_SERVER + `/api/task/parentTaskId/${payload.parentTaskId}/getTaskByParentId`;
  
        const Authorization = composeAuth(payload?.jwt);
  
        const res = await fetch(url, {
          method: "PATCH",
          headers: {
            "Content-type": "application/json",
            Authorization,
          },
        });
  
        const response = await res.json();
  
        const validateData = checkStatus(response);
        return validateData;
      } catch (err) {
        if (err instanceof AuthError) {
          thunkApi.dispatch(logoutUser());
        }
        return thunkApi.rejectWithValue(err);
      }
    }
  );
  export const editTaskById = createAsyncThunk(
    "tasks/editTaskById",
    async (payload, thunkApi) => {
      try {
        const url = process.env.REACT_APP_SERVER + `/api/task/${payload.id}/institution/${payload.institutionId}/editTaskById`;
  
        const Authorization = composeAuth(payload?.jwt);
  
        const res = await fetch(url, {
          method: "PATCH",
          headers: {
            "Content-type": "application/json",
            Authorization,
          },
          body: JSON.stringify(payload?.requestBody),
        });
  
        const response = await res.json();
  
        const validateData = checkStatus(response);
        return validateData;
      } catch (err) {
        if (err instanceof AuthError) {
          thunkApi.dispatch(logoutUser());
        }
        return thunkApi.rejectWithValue(err);
      }
    }
  );

export const postTask = createAsyncThunk(
  "tasks/postTask",
  async (payload, thunkApi) => {
    try {
      const url = process.env.REACT_APP_SERVER + "/api/task";

      const Authorization = composeAuth(payload?.jwt);

      const res = await fetch(url, {
        method: "POST",
        headers: {
          "Content-type": "application/json",
          Authorization,
        },
        body: JSON.stringify(payload?.requestBody),
      });

      const response = await res.json();

      const validateData = checkStatus(response);
      return validateData;
    } catch (err) {
      if (err instanceof AuthError) {
        thunkApi.dispatch(logoutUser());
      }
      return thunkApi.rejectWithValue(err);
    }
  }
);

export const deleteTask = createAsyncThunk(
    "tasks/deleteTask",
    async (payload, thunkApi) => {
      try {
        const url = process.env.REACT_APP_SERVER + `/api/task/${payload.id}/taskDeleteById`;
  
        const Authorization = composeAuth(payload?.jwt);
  
        const res = await fetch(url, {
          method: "DELETE",
          headers: {
            "Content-type": "application/json",
            Authorization,
          },
          body: JSON.stringify(payload?.requestBody),
        });
  
        const response = await res.json();
  
        const validateData = checkStatus(response);
        return validateData;
      } catch (err) {
        if (err instanceof AuthError) {
          thunkApi.dispatch(logoutUser());
        }
        return thunkApi.rejectWithValue(err);
      }
    }
  );
  


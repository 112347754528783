import { Outlet, useLocation } from "react-router-dom";
import version from '../../package.json'
// @mui
import { Box } from "@mui/system";
import { styled } from "@mui/material/styles";
// components
import Logo from "../components/Logo";
import LogoMain from "../components/LogoMain";
import { HEADER, NAVBAR } from "../config";
import useCollapseDrawer from "src/hooks/useCollapseDrawer";
import {
  getVersion, getError, getIsLoadingVersionTracking, getVersionFirstLoad, setVersionFirstLoad
} from "../redux/slices/versionTracking";

import { getVersionTracking } from '../redux/actions/versionTracking'
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Dialog, Stack, Typography } from "@mui/material";
import Iconify from "src/components/Iconify";
import { usePrevious } from "src/helpers/utils";

// ----------------------------------------------------------------------

const LayoutStyle = styled("div")(({ theme }) => ({
  top: 0,
  left: 0,
  lineHeight: 0,
  width: "100%",
  position: "absolute",
  padding: theme.spacing(3, 3, 0),
  [theme.breakpoints.up("sm")]: {
    padding: theme.spacing(5, 5, 0),
  },
}));

// ----------------------------------------------------------------------

export default function PublicLayout() {
  const { collapseClick, isCollapse } = useCollapseDrawer();
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const [showModal, setShowModal] = useState(false);

  const error = useSelector(getError);
  const versionFirstLoad = useSelector(getVersionFirstLoad);
  const isLoadingVersionTracking = useSelector(getIsLoadingVersionTracking);
  const isLoadingVersionTrackingPrev = usePrevious(isLoadingVersionTracking);
  const versionCurrent = useSelector(getVersion);
  const versionCurrentPrev = usePrevious(versionCurrent);

  useEffect(() => {
    dispatch(getVersionTracking())
  }, [pathname])

  useEffect(() => {
    if (
      !error &&
      !isLoadingVersionTracking &&
      isLoadingVersionTracking !== isLoadingVersionTrackingPrev &&
      isLoadingVersionTrackingPrev !== undefined
    ) {
      if (versionCurrent?.version !== version?.version && !versionFirstLoad) {
        setShowModal(true)
      }
    }
  }, [isLoadingVersionTracking, isLoadingVersionTrackingPrev])

  return (
    <>
      <LayoutStyle>
        {/* <LogoMain /> */}

        <Outlet />
        <Dialog
          fullWidth
          maxWidth="sm"
          open={showModal}
          onClose={() => { setShowModal(false); dispatch(setVersionFirstLoad(true)) }
          }
        > <>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="center"
              sx={{ py: 1, px: 3, textAlign: "center" }}
            >
              <Iconify
                icon="mdi:alert-circle-outline"
                style={{ color: "black", width: "100px", height: "50px" }}
              />
            </Stack>
            <Stack
              sx={{
                py: 1,
                px: 3,
                textAlign: "center",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <div
                style={{
                  padding: 10,
                  display: "flex",
                  justifyContent: "center",
                  alignSelf: "center",
                  fontSize: "22px",
                  color: "rgba(75, 75, 75, 0.76)",
                }}
              >
                There is a new update, do you want to reload?
              </div>
            </Stack>

            <Stack
              sx={{
                py: 2,
                px: 3,
                textAlign: "center",
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
              }}
            >
              <Button
                size="medium"
                variant="outlined"
                sx={{ alignSelf: "flex-end", marginRight: "5px" }}
                onClick={() => { setShowModal(false); dispatch(setVersionFirstLoad(true)) }}
              >
                DISMISS
              </Button>
              <Button variant="contained" onClick={() => { dispatch(setVersionFirstLoad(true)); setShowModal(false); window?.location?.reload(true); }}>
                RELOAD
              </Button>
            </Stack>
          </>
        </Dialog>
        <Typography>v{version?.version}</Typography>
      </LayoutStyle>
    </>
  );
}

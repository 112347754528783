import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  tableFilters: {},
  columnFilters: {},
  selectedAllGoals:false,
  selectedGoalsIds:[],
  unselectedGoalsIds:[],
  tabValue:{},
  rowsPerPage: 20,
};

const goalsUISlice = createSlice({
  name: "goalsUI",
  initialState,
  reducers: {
    "account/logoutUser": (state) => initialState,
    storeGoalFilters(state, action) {
      state.tableFilters = action.payload;
    },
    clearGoalFilters(state, action) {
      state.tableFilters = {};
    },
    storeGoalColumnFilters(state, action) {
      state.columnFilters = action.payload;
    },
    setSelectedAllGoals(state, action) {
      state.selectedAllGoals = action.payload;
    },
    setSelectedGoalsIds(state, action) {
      state.selectedGoalsIds = action.payload;
    },
    setUnselectedGoalsIds(state, action) {
      state.unselectedGoalsIds = action.payload;
    },  
    setTabValue(state, action) {
      const { id, value } = action.payload;
      state.tabValue = {
        ...state.tabValue,  
        [id]: value,      
      };
    },
    setRowsPerPage(state, action) {
      const {id,value}=action.payload;
      state.rowsPerPage = {
        ...state.rowsPerPage,
        [id]:value
      }
    },
  },
  extraReducers: {},
});

export const getTableFilters = (state) => state.goalsUI.tableFilters;
export const getColumnFilters = (state) => state.goalsUI.columnFilters;
export const getSelectedAllGoals = (state) => state.goalsUI.selectedAllGoals;
export const getSelectedGoalsIds = (state) => state.goalsUI.selectedGoalsIds;
export const getUnselectedGoalsIds = (state) => state.goalsUI.unselectedGoalsIds;
export const getTabValue = (state) => state.goalsUI.tabValue;
export const getRowsPerPage = (state) => state.goalsUI.rowsPerPage;

export const {
  storeGoalFilters,
  clearGoalFilters,
  storeGoalColumnFilters,
  setSelectedAllGoals,
  setSelectedGoalsIds,
  setUnselectedGoalsIds,
  setTabValue,
  setRowsPerPage
} = goalsUISlice.actions;

export default goalsUISlice;

import { createAsyncThunk } from "@reduxjs/toolkit";
import { checkStatus, composeAuth, AuthError } from "../../helpers/utils";

import { getInstitutionByIds } from "../actions/institution";

export const loginUser = createAsyncThunk(
  "account/loginUser",
  async (payload, thunkApi) => {
    try {
      const url = process.env.REACT_APP_SERVER + "/api/user/login";
      const res = await fetch(url, {
        method: "POST",
        body: JSON.stringify(payload),
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      });

      const response = await res.json();
      const validateData = checkStatus(response);
      if (
        validateData?.data?.institutionId &&
        validateData?.data?.institutionId?.length
      ) {
        thunkApi.dispatch(
          getInstitutionByIds({
            jwt: validateData?.data?.jwt,
            ids: validateData?.data?.institutionId,
          })
        );
      }
      return validateData?.data;
    } catch (err) {
      return thunkApi.rejectWithValue(err);
    }
  }
);

export const loginUserSelectInstitution = createAsyncThunk(
  "account/loginUserSelectInstitution",
  async (payload, thunkApi) => {
    try {
      const Authorization = composeAuth(payload?.jwt);
      const url = process.env.REACT_APP_SERVER + "/api/user/select/institution";
      const res = await fetch(url, {
        method: "POST",
        body: JSON.stringify(payload?.requestBody),
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization
        }
      });
      const response = await res.json();
      const validateData = checkStatus(response); 
      return validateData?.data;
    } catch (err) {
      return thunkApi.rejectWithValue(err);
    }
  }
);

export const logoutUser = createAsyncThunk(
  "account/logoutUser",
  async (jwt, thunkApi) => {
    try {
      const Authorization = composeAuth(jwt);
      const url = process.env.REACT_APP_SERVER + "/api/user/logout";
      const res = await fetch(url, {
        method: "DELETE",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization,
        },
      });

      const response = await res.json();
      const validateData = checkStatus(response);
      return validateData;
    } catch (err) {
      return thunkApi.rejectWithValue(err);
    }
  }
);

export const forgotPassword = createAsyncThunk(
  "account/forgotPassword",
  async (payload, thunkApi) => {
    try {
      const url =
        process.env.REACT_APP_SERVER + `/api/user/email/${payload.email}/otp`;
      const res = await fetch(url, {
        method: "PATCH",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      });

      const response = await res.json();
      const validateData = checkStatus(response);
      return validateData?.data;
    } catch (err) {
      return thunkApi.rejectWithValue(err);
    }
  }
);

export const resendLink = createAsyncThunk(
  "account/resendLink",
  async (payload, thunkApi) => {
    try {
      const url =
        process.env.REACT_APP_SERVER + `/api/user/email/${payload.email}/otp`;
      const res = await fetch(url, {
        method: "PATCH",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      });

      const response = await res.json();
      const validateData = checkStatus(response);
      return validateData?.data;
    } catch (err) {
      return thunkApi.rejectWithValue(err);
    }
  }
);

export const verifyEmailOtp = createAsyncThunk(
  "account/verifyEmailOtp",
  async (otp, thunkApi) => {
    try {

      const url =
        process.env.REACT_APP_SERVER + `/api/user/verify-email/otp/${otp}`;
      const res = await fetch(url, {
        method: "PATCH",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      });

      const response = await res.json();
      const validateData = checkStatus(response);
      if (validateData?.data?.institutionId && validateData?.data?.institutionId?.length) {
        thunkApi.dispatch(getInstitutionByIds({
          jwt: validateData?.data?.jwt,
          ids: validateData?.data?.institutionId
        }))
      }
      return validateData?.data;
    } catch (err) {
      return thunkApi.rejectWithValue(err);
    }
  }
);

export const setPassword = createAsyncThunk(
  "account/setPassword",
  async ({ jwt, payload }, thunkApi) => {
    try {
      const Authorization = composeAuth(jwt);
      const url = process.env.REACT_APP_SERVER + `/api/user/set-password`;
      const res = await fetch(url, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization,
        },
        body: JSON.stringify(payload),
      });

      const response = await res.json();
      const validateData = checkStatus(response);
      return validateData?.data;
    } catch (err) {
      return thunkApi.rejectWithValue(err);
    }
  }
);


export const resetAdminPassword = createAsyncThunk(
  "account/resetAdminPassword",
  async (payload, thunkApi) => {
    try {
      const url = process.env.REACT_APP_SERVER + `/api/user/email/${payload?.email}/otp`;
      const res = await fetch(url, {
        method: "PATCH",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      });

      const response = await res.json();
      const validateData = checkStatus(response);
      return validateData;
    } catch (err) {
      return thunkApi.rejectWithValue(err);
    }
  }
);


export const createUser = createAsyncThunk(
  "account/createUser",
  async (payload, thunkApi) => {
    try {
      const Authorization = composeAuth(payload?.jwt);
      const url = process.env.REACT_APP_SERVER + `/api/profiles/credential/${payload?.id}`;
      const res = await fetch(url, {
        method: "PATCH",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization,
        },
        body: JSON.stringify(payload?.data),
      });

      const response = await res.json();
      const validateData = checkStatus(response);
      return validateData;
    } catch (err) {
      return thunkApi.rejectWithValue(err);
    }
  }
);

export const addGoogleAccessToken = createAsyncThunk(
  "events/addGoogleAccessToken",
  async (payload, thunkApi) => {
    try {
      const Authorization = composeAuth(payload?.jwt);
      const url = process.env.REACT_APP_SERVER + `/api/user/googletoken`;
      const res = await fetch(url, {
        method: "POST",
        headers: {
          "Content-type": "application/json",
          Authorization,
        },
        body: JSON.stringify(payload?.requestBody),
      });

      const response = await res.json();
      const validateData = checkStatus(response);
      return validateData;
    } catch (err) {
      if (err instanceof AuthError) {
        thunkApi.dispatch(logoutUser());
      }
      return thunkApi.rejectWithValue(err);
    }
  }
);

export const removeGoogleAccessToken = createAsyncThunk(
  "events/removeGoogleAccessToken",
  async (payload, thunkApi) => {
    try {
      const Authorization = composeAuth(payload?.jwt);
      const url = process.env.REACT_APP_SERVER + `/api/user/googletoken`;
      const res = await fetch(url, {
        method: "DELETE",
        headers: {
          "Content-type": "application/json",
          Authorization,
        },
        body: JSON.stringify(payload?.requestBody),
      });

      const response = await res.json();

      const validateData = checkStatus(response);
      return validateData;
    } catch (err) {
      if (err instanceof AuthError) {
        thunkApi.dispatch(logoutUser());
      }
      return thunkApi.rejectWithValue(err);
    }
  }
);

export const addOutlookAccessToken = createAsyncThunk(
  "events/addOutlookAccessToken",
  async (payload, thunkApi) => {
    try {
      const Authorization = composeAuth(payload?.jwt);
      const url = process.env.REACT_APP_SERVER + `/api/user/outlooktoken`;
      const res = await fetch(url, {
        method: "POST",
        headers: {
          "Content-type": "application/json",
          Authorization,
        },
        body: JSON.stringify(payload?.requestBody),
      });

      const response = await res.json();
      const validateData = checkStatus(response);
      return validateData;
    } catch (err) {
      if (err instanceof AuthError) {
        thunkApi.dispatch(logoutUser());
      }
      return thunkApi.rejectWithValue(err);
    }
  }
);

export const removeOutlookAccessToken = createAsyncThunk(
  "events/removeOutlookAccessToken",
  async (payload, thunkApi) => {
    try {
      const Authorization = composeAuth(payload?.jwt);
      const url = process.env.REACT_APP_SERVER + `/api/user/outlooktoken`;
      const res = await fetch(url, {
        method: "DELETE",
        headers: {
          "Content-type": "application/json",
          Authorization,
        },
        body: JSON.stringify(payload?.requestBody),
      });

      const response = await res.json();

      const validateData = checkStatus(response);
      return validateData;
    } catch (err) {
      if (err instanceof AuthError) {
        thunkApi.dispatch(logoutUser());
      }
      return thunkApi.rejectWithValue(err);
    }
  }
);
import { createSlice } from "@reduxjs/toolkit";
import { logoutUser } from "../actions/account";
import { getTaskByInstitutionId, postTask ,editTaskById,deleteTask, getTaskByProfileId, getTaskByProfile, getTaskByModuleId} from "../actions/tasks";

const initialState = {
    error: false,
    isLoadingPostTask:false,
    isLoadingGetTaskByInstitutionId:false,
    isLoadingDeleteTaskById:false,
    isLoadingEditTaskById:false,
    tasksData:[],
    subTasksData:[]
};

const taskSlice = createSlice({
    name: "tasks",
    initialState,
    extraReducers: {
        [getTaskByInstitutionId.pending]: (state, action) => {
            state.isLoadingGetTaskByInstitutionId = true;
            state.error = false;
        },
        [getTaskByInstitutionId.fulfilled]: (state, action) => {
            state.isLoadingGetTaskByInstitutionId = false;
            state.tasksData = action?.payload?.data?.task;
            state.subTasksData = action?.payload?.data?.subtask;
            state.error = false;
        },
        [getTaskByInstitutionId.rejected]: (state, action) => {
            state.isLoadingGetTaskByInstitutionId = false;
            state.error = true;
        },
        [getTaskByProfileId.pending]: (state, action) => {
            state.isLoadingGetTaskByInstitutionId = true;
            state.error = false;
        },
        [getTaskByProfileId.fulfilled]: (state, action) => {
            state.isLoadingGetTaskByInstitutionId = false;
            state.tasksData = action?.payload?.data?.task;
            state.subTasksData = action?.payload?.data?.subtask;
            state.error = false;
        },
        [getTaskByProfileId.rejected]: (state, action) => {
            state.isLoadingGetTaskByInstitutionId = false;
            state.error = true;
        },
        
        [getTaskByProfile.pending]: (state, action) => {
            state.isLoadingGetTaskByInstitutionId = true;
            state.error = false;
        },
        [getTaskByProfile.fulfilled]: (state, action) => {
            state.isLoadingGetTaskByInstitutionId = false;
            state.tasksData = action?.payload?.data?.task;
            state.subTasksData = action?.payload?.data?.subtask;
            state.error = false;
        },
        [getTaskByProfile.rejected]: (state, action) => {
            state.isLoadingGetTaskByInstitutionId = false;
            state.error = true;
        },
        [getTaskByModuleId.pending]: (state, action) => {
            state.isLoadingGetTaskByInstitutionId = true;
            state.error = false;
        },
        [getTaskByModuleId.fulfilled]: (state, action) => {
            state.isLoadingGetTaskByInstitutionId = false;
            state.tasksData = action?.payload?.data?.task;
            state.subTasksData = action?.payload?.data?.subtask;
            state.error = false;
        },
        [getTaskByModuleId.rejected]: (state, action) => {
            state.isLoadingGetTaskByInstitutionId = false;
            state.error = true;
        },
        [postTask.pending]: (state, action) => {
            state.isLoadingPostTask = true;
            state.error = false;
        },
        [postTask.fulfilled]: (state, action) => {
            state.isLoadingPostTask = false;
            state.error = false;
        },
        [postTask.rejected]: (state, action) => {
            state.isLoadingPostTask = false;
            state.error = true;
        },
        [editTaskById.pending]: (state, action) => {
            state.isLoadingEditTaskById = true;
            state.error = false;
        },
        [editTaskById.fulfilled]: (state, action) => {
            state.isLoadingEditTaskById = false;
            state.error = false;
        },
        [editTaskById.rejected]: (state, action) => {
            state.isLoadingEditTaskById = false;
            state.error = true;
        },
        [deleteTask.pending]: (state, action) => {
            state.isLoadingDeleteTaskById = true;
            state.error = false;
        },
        [deleteTask.fulfilled]: (state, action) => {
            state.isLoadingDeleteTaskById = false;
            state.error = false;
        },
        [deleteTask.rejected]: (state, action) => {
            state.isLoadingDeleteTaskById = false;
            state.error = true;
        },
        [logoutUser.fulfilled]: (state) => {
            return initialState;
        },
        [logoutUser.rejected]: (state) => {
            return initialState;
        },
    },
});

export const getError = (state) => state.tasks.error;
export const getTasksByInstitutionId=(state)=>state.tasks.tasksData;
export const getSubTasksByInstitutionId=(state)=>state.tasks.subTasksData;
export const getIsLoadingGetTaskByInstitutionId=(state)=>state.tasks.isLoadingGetTaskByInstitutionId;
export const getIsLoadingPostTask=(state)=>state.tasks.isLoadingPostTask;
export const getIsLoadingDeleteTask=(state)=>state.tasks.isLoadingDeleteTaskById;
export const getIsLoadingEditTaskById=(state)=>state.tasks.isLoadingEditTaskById;
export default taskSlice;
